<template>
  <div class="field col-span-12 md:col-span-12 mb-0">
    <label :for="`${addressType}-address-lines`">{{ t("common.address.address-lines") }}</label>
    <PrimeTextarea
      :id="`${addressType}-address-lines`"
      v-model="addressLinesComputed"
      type="text"
      class="inputfield w-full"
      :placeholder="t('placeholder.select', { property: t('common.address.address-lines').toLowerCase() })"
      :class="{ 'p-invalid': val.addressLines.$error }"
      :data-testid="`${addressType}-address-lines`"
    />
    <small class="p-error" v-if="val.addressLines.$error" :data-testid="`${addressType}-address-lines-error`">
      {{ val.addressLines.$errors[0].$message }}
    </small>
  </div>
  <div class="field col-span-12 md:col-span-12 mb-0">
    <label :for="`${addressType}-country`">{{ t("common.address.country") }}</label>
    <Select
      :id="`${addressType}-country`"
      :options="countryOptions"
      optionLabel="name"
      optionValue="iso"
      v-model="selectedCountryComputed"
      class="inputfield w-full"
      :class="{ 'p-invalid': val.countryIso.$error }"
      :placeholder="t('placeholder.select', { property: t('common.address.country').toLowerCase() })"
      :data-testid="`${addressType}-country`"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
      :pt:list:data-testid="`${addressType}-country-list`"
    />
    <small class="p-error" v-if="val.countryIso.$error" :data-testid="`${addressType}-country-error`">{{
      val.countryIso.$errors[0].$message
    }}</small>
  </div>

  <div class="field col-span-12 md:col-span-12 mb-0">
    <label :for="`${addressType}-postal-code`">{{ t("common.address.postal-code-city") }}</label>
    <div class="formgrid grid grid-cols-12 gap-4">
      <div class="field col-span-12 md:col-span-4">
        <InputText
          :id="`${addressType}-postal-code`"
          v-model="addressComputed.postalCode"
          maxlength="4"
          type="text"
          class="inputfield w-full"
          :class="{ 'p-invalid': val.postalCode.$error }"
          :placeholder="t('placeholder.select', { property: t('common.address.postal-code-city').toLowerCase() })"
          @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
          :data-testid="`${addressType}-postal-code`"
        />
        <small class="p-error" v-if="val.postalCode.$error" :data-testid="`${addressType}-postal-code-error`">{{
          val.postalCode.$errors[0].$message
        }}</small>
      </div>
      <div class="field col-span-12 md:col-span-8">
        <InputText
          :id="`${addressType}-city`"
          type="text"
          v-model="addressComputed.city"
          class="inputfield w-full"
          :class="{ 'p-invalid': val.city.$error }"
          :placeholder="t('placeholder.select', { property: t('common.address.postal-code-city').toLowerCase() })"
          :data-testid="`${addressType}-city`"
          :aria-label="t('common.address.city')"
        />
        <small class="p-error" v-if="val.city.$error" :data-testid="`${addressType}-city-error`">{{
          val.city.$errors[0].$message
        }}</small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ClientAddress } from "@/repositories/client/model/ClientAddress";
import { Country } from "@/repositories/country/model/Country";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { required } from "@/locales/i18n-validators";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCountry } from "@/repositories/country/CountryService";

const { t } = useI18n();

const props = defineProps<{
  address: ClientAddress;
  countryOptions: Country[];
  addressType: string;
}>();

const isActive = ref(false);

const addressComputed = computed(() => {
  return props.address ?? ({} as ClientAddress);
});

const addressLinesComputed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines ? addressComputed.value.addressLines.join("\n") : "";
  },
  set: (val) => {
    addressComputed.value.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    return addressComputed.value?.countryIso ?? "";
  },
  set: (value) => {
    if (value !== undefined) {
      addressComputed.value.countryIso = value;
    }
  },
});

const { getCities } = useCountry();

const onPostalCodeChange = async (code: string) => {
  if (code === undefined || code.length < 3) {
    return;
  }
  const response = await getCities(code);
  addressComputed.value.city = response[0].city;
};

const rules = {
  addressLines: {
    required,
    $each: helpers.withMessage(t("common.address.address-lines-error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
  countryIso: {
    required,
  },
};

const val = useVuelidate(rules, addressComputed);
</script>
