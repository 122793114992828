<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        data-testid="btn-cancel"
        rounded
        size="small"
        variant="text"
        @click="routeToCustomerList"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800">{{ editMode ? title : t("employee.new-employee") }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <div v-tooltip.bottom="saveButtonTooltip" class="inline-block">
        <Button
          class="c-circular-button"
          data-testid="btn-save"
          :disabled="!hasUpdates || isSaving"
          @click="emit('onCommit')"
        >
          <i class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ editMode ? t("common.update") : t("common.add") }}</span>
        </Button>
      </div>

      <Button
        v-if="editMode"
        class="c-circular-button ml-4"
        data-testid="btn-delete"
        :disabled="isSaving"
        @click="onConfirmDelete"
      >
        <i class="pi pi-trash c-delete-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.delete") }}</span>
      </Button>
      <ConfirmPopup data-testid="employee-delete-confirm"></ConfirmPopup>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useConfirm } from "primevue/useconfirm";

const { t } = useI18n();

const confirm = useConfirm();

const props = defineProps<{
  hasUpdates: boolean;
  isSaving: boolean;
  editMode: boolean;
  title?: string;
}>();

const emit = defineEmits<{
  (e: "onCommit"): void;
  (e: "onDelete"): void;
  (e: "onCancel"): void;
}>();

const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("employee.delete.dialog"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("onDelete");
    },
  });
};

const saveButtonTooltip = computed(() => {
  return props.hasUpdates ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

const router = useRouter();

const routeToCustomerList = () => {
  if (window.history.state.back === null || window.history.state.back.indexOf("/system-data/employees") === -1) {
    router.push({ name: "employee-search", query: { search: "" } });
  } else {
    router.back();
  }
};
</script>
<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}
.c-circular-button:disabled {
  opacity: 0.6;
}
</style>
