<template>
  <DataTable
    :value="printers"
    striped-rows
    data-key="documentType"
    class="c-datatable"
    responsive-layout="scroll"
    scroll-height="400px"
    :loading="loading"
    :row-hover="true"
    data-testid="default-printers-list"
    removable-sort
    :rows="pageSize"
    :paginator="true"
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :current-page-report-template="
      t('common.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    @row-click="onRowClick"
  >
    <Column field="documentType" :header="t('printing.document-type.label')" :sortable="true">
      <template #body="{ data }">
        {{ t(`printing.document-type.${data.documentType.toLowerCase()}`) }}
      </template>
    </Column>
    <Column field="printerName" :header="t('printing.name')" :sortable="true"></Column>
    <Column field="location" :header="t('printing.location')" :sortable="true"></Column>
    <Column field="description" :header="t('printing.description')" :sortable="true"> </Column>
    <template #empty>
      {{ t("printing.no-printers-found") }}
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { DefaultPrinterInfo } from "../models/DefaultPrinterInfo";
import { type DataTableRowClickEvent } from "primevue/datatable";
import { ref } from "vue";

defineProps<{ printers: DefaultPrinterInfo[]; loading: boolean }>();

const pageSize = ref<number>(25);

const emit = defineEmits<{
  (e: "defaultPrinterClicked", printer: DefaultPrinterInfo): void;
}>();

const { t } = useI18n();

const onRowClick = (event: DataTableRowClickEvent) => {
  emit("defaultPrinterClicked", event.data);
};
</script>

<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
