<template>
  <FloatLabel variant="on">
    <InputText
      id="initials"
      v-model="initialsComputed"
      v-tooltip.focus.bottom="{
        value: t('placeholder.select', { property: t('employee.initials').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="initials"
      class="w-full"
      :invalid="val.initials.$error"
      maxlength="10"
    />
    <label for="first-name">{{ t("employee.initials") }}</label>
  </FloatLabel>
  <small v-if="val.initials.$error" class="p-error" data-testid="initials-error">
    {{ val.initials.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { computed } from "vue";

const props = defineProps<{
  initials: string;
}>();

const emit = defineEmits<{
  (e: "update:initials", value: string): void;
}>();

const { t } = useI18n();

const initialsComputed = computed<string>({
  get: () => {
    return props.initials ?? "";
  },
  set: (value) => {
    emit("update:initials", value);
  },
});

const rules = {
  initials: {
    required,
  },
};

const val = useValidate(rules, props);
</script>
