<template>
  <CompanyHeader @on-close="routeToDashboard" />
  <div class="m-4">
    <Card>
      <template #content>
        <div class="grid grid-cols-12 gap-4 m-4">
          <div class="col-span-12 lg:col-span-6 mr-4">
            <div class="formgrid grid grid-cols-12 gap-8">
              <div class="field col-span-12 lg:col-span-6">
                <CompanyName v-model:company-name="company.name" :disabled="true" />
              </div>
              <div class="field col-span-12 lg:col-span-6">
                <OrganizationNumber v-model:organization-number="company.organizationNumber" :disabled="true" />
              </div>
              <div class="field col-span-12 lg:col-span-6">
                <Email v-model:email="company.email" :disabled="true" />
              </div>
              <div class="field col-span-12 lg:col-span-6">
                <CumulusDatePicker
                  id="company-registration-date"
                  v-model:date="company.registrationDate"
                  :label="t('company.registration-date')"
                  :placeholder="t('placeholder.select', { property: t('company.registration-date').toLowerCase() })"
                  :disabled="true"
                />
              </div>
              <div class="field col-span-12 lg:col-span-6">
                <TelephoneNumber v-model:telephone-number="company.phoneNumber" :disabled="true" />
              </div>
            </div>
          </div>

          <div class="col-span-12 lg:col-span-6">
            <div class="formgrid grid grid-cols-12 gap-6 border-l-2 border-gray-200 pl-8">
              <Address
                :address="company.address"
                :country-options="countryOptions"
                address-type="company"
                :disabled="true"
                @set-address="setCompanyAddress"
              />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { ShortcutAction, useShortcut } from "@cumulus/components";
import { type Company } from "@/repositories/company/model/Company";

import { type Address as AddressModel } from "@/repositories/company/model/Address";
import { type Country } from "@/repositories/country/model/Country";
import { CumulusDatePicker } from "@cumulus/components";

import CompanyName from "./components/CompanyName.vue";
import OrganizationNumber from "./components/OrganizationNumber.vue";
import Email from "./components/Email.vue";
import TelephoneNumber from "./components/TelephoneNumber.vue";
import Address from "./components/Address.vue";
import CompanyHeader from "./components/CompanyHeader.vue";
import cloneDeep from "lodash.clonedeep";
import { useCountry } from "@/repositories/country/CountryService";
import { useCompany } from "@/repositories/company/CompanyService";

const router = useRouter();
const { t } = useI18n();
const company = ref<Company>({
  id: "",
  name: "",
  email: "",
  registrationDate: "",
  organizationNumber: "",
  active: false,
  phoneNumber: "",
  address: {
    addressLines: [""],
    postalCode: "",
    city: "",
    countryIso: "",
  },
});

const { getCompany } = useCompany();

const loadCompany = async () => {
  company.value = await getCompany();
};

onMounted(loadCompany);

const routeToDashboard = () => {
  router.push("/");
};

const { getCountries } = useCountry();

const countryOptions = ref<Country[]>([]);
const fetchCountryData = async () => {
  countryOptions.value = await getCountries();
};

onMounted(fetchCountryData);

const setCompanyAddress = (value: AddressModel) => {
  const address = cloneDeep(value);
  company.value.address = address;
};
useShortcut(ShortcutAction.cancel, routeToDashboard);
</script>
