<template>
  <FloatLabel variant="on">
    <InputText
      id="company-name"
      v-model="companyNameComputed"
      data-testid="company-name"
      class="inputfield w-full"
      :invalid="val.companyName.$error"
      :disabled="disabled"
      maxlength="100"
    />
    <label for="company-name">{{ t("company.name") }}</label>
  </FloatLabel>
  <small v-if="val.companyName.$error" class="p-error" data-testid="company-name-error">
    {{ val.companyName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { type WritableComputedRef, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  companyName: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "update:companyName", value: string): void;
}>();

const { t } = useI18n();

const companyNameComputed: WritableComputedRef<string> = computed({
  get: () => {
    return props.companyName ?? "";
  },
  set: (value) => {
    emit("update:companyName", value);
  },
});

const rules = {
  companyName: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
