<template>
  <FloatLabel variant="on">
    <Select
      id="role"
      v-model="roleSelected"
      v-tooltip.focus.bottom="{
        value: t('placeholder.select', { property: t('employee.roles').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="role"
      pt:list:data-testid="role-list"
      class="w-full"
      option-label="name"
      option-value="id"
      :options="roleOptions"
    />
    <label for="role">
      {{ t("employee.roles") }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Role } from "@/repositories/employee/role/model/Role";

const { t } = useI18n();

const props = defineProps<{ role: string; roleOptions: Role[] }>();
const emit = defineEmits<{ (e: "update:role", value: string): void }>();

const roleSelected = computed<string>({
  get: () => props.role,
  set: (role) => emit("update:role", role),
});
</script>
