<template>
  <div class="field py-2 mt-2">
    <FloatLabel variant="on">
      <InputText
        id="name"
        v-model="bankingComputed.name"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('client.banking.name').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        type="text"
        data-testid="banking-name"
        class="inputfield w-full"
        :class="{ 'p-invalid': val.banking.name.$error }"
      />
      <label for="name">
        {{ t(`client.banking.name`) }}
      </label>
    </FloatLabel>
    <small v-if="val.banking.name.$error" data-testid="banking-name-error" class="p-error">{{
      val.banking.name.$errors[0].$message
    }}</small>
  </div>
  <div class="field py-2 mt-2">
    <FloatLabel variant="on">
      <InputText
        id="account-number"
        v-model="bankingComputed.accountNumber"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('client.banking.account-number').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
        type="text"
        data-testid="banking-account-number"
        class="inputfield w-full"
        aria-describedby="city-help"
        :class="{ 'p-invalid': val.banking.accountNumber.$error }"
      />
      <label for="account-number">
        {{ t(`client.banking.account-number`) }}
      </label>
    </FloatLabel>
    <small v-if="val.banking.accountNumber.$error" data-testid="banking-account-number-error" class="p-error">{{
      val.banking.accountNumber.$errors[0].$message
    }}</small>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { ClientBanking } from "@/repositories/client/model/ClientBanking";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  banking: ClientBanking;
}>();

const emit = defineEmits<{
  (e: "update:banking", value: ClientBanking): void;
}>();

const { t } = useI18n();

const bankingComputed = computed<ClientBanking>({
  get: () => {
    return props.banking ?? ({} as ClientBanking);
  },
  set: (value) => {
    emit("update:banking", value);
  },
});

const rules = {
  banking: {
    name: {
      required,
    },
    accountNumber: {
      required,
    },
  },
};

const val = useVuelidate(rules, props);
</script>
