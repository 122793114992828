<template>
  <FloatLabel variant="on">
    <Select
      id="default-client"
      v-model="defaultClient"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.default-client').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="default-client"
      pt:list:data-testid="default-client-list"
      class="w-full"
      option-label="name"
      option-value="id"
      :options="clientOptions"
      :invalid="val.defaultClientId.$error"
    />

    <label for="default-client">{{ t("employee.default-client") }}</label>
  </FloatLabel>
  <small v-if="val.defaultClientId.$error" class="p-error" data-testid="default-client-error">
    {{ val.defaultClientId.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { type Client } from "@/repositories/employee/client/model/Client";
import useValidate from "@vuelidate/core";

const { t } = useI18n();

const props = defineProps<{ defaultClientId: string; clientOptions: Client[] }>();
const emit = defineEmits<{ (e: "update:defaultClientId", value: string): void }>();

const defaultClient = computed<string>({
  get: () => props.defaultClientId,
  set: (client) => emit("update:defaultClientId", client),
});

const val = useValidate({ defaultClientId: { required } }, props);
</script>
