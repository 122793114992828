<template>
  <FloatLabel variant="on">
    <Select
      id="menu-style"
      v-model="menuStyle"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.menu-style').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-test-id="menu-style"
      pt:list:data-testid="menu-style-list"
      class="w-full"
      option-label="name"
      option-value="value"
      :options="dropdownOptions"
    />

    <label for="menu-style">
      {{ t(`employee.menu-style`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { MenuStyle } from "@cumulus/event-bus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const menuStyle = defineModel<string>("menuStyle");

const { t } = useI18n();

const dropdownOptions = computed(() => {
  const keys = Object.keys(MenuStyle);
  return keys.map((key) => {
    return { name: t(`employee.menu-style-type.${key.toLowerCase()}`), value: key };
  });
});
</script>
