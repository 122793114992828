<template>
  <div v-if="loadFailed" class="m-4">
    <Card>
      <template #content
        >{{ t("common.error-load", { entity: t("client.clients").toLowerCase(), errorReason: errorReason }) }}
      </template>
    </Card>
  </div>
  <div v-else class="m-4">
    <Card>
      <template #content>
        <ClientList
          ref="clientListRef"
          :clients="clients"
          :loading="loading"
          :page="page"
          :page-size="pageSize"
          :total-hits="totalHits"
          @update:page-size="onUpdatePageSize"
          @on-export="exportCSV"
          @clients-lists-refresh="onRefreshList"
        />
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";

import { Client } from "@/repositories/client/model/Client";
import { useClient } from "@/repositories/client/ClientService";
import ClientList from "../components/ClientList.vue";

const { t } = useI18n();
const totalHits = ref(0);
const errorReason = ref("");
const loading = ref(false);
const loadFailed = ref(false);
const clients = ref<Array<Client>>([]);

const pageSize = ref<number>(50);
const page = ref(1);
const clientListRef = ref();

const exportCSV = () => {
  clientListRef.value.exportCSV();
};

const onRefreshList = async () => {
  loading.value = true;
  await loadClients();
};

const { getClients } = useClient();

const loadClients = async () => {
  try {
    clients.value = await getClients();
    totalHits.value = clients.value.length;
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  loading.value = true;
  await loadClients();
});

const onUpdatePageSize = async (value: number) => {
  pageSize.value = value;
  loading.value = true;
  await loadClients();
};
</script>
