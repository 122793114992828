<template>
  <FloatLabel variant="on">
    <InputMask
      id="company-telephone-number"
      v-model="telephoneNo"
      mask="999 99 999"
      data-testid="company-telephone-number"
      class="inputfield w-full"
      :invalid="val.telephoneNumber.$error"
      :disabled="disabled"
      @blur="updateTelephoneNumber"
    />
    <label for="company-telephone-number">{{ t("company.telephone-number") }}</label>
  </FloatLabel>
  <small v-if="val.telephoneNumber.$error" class="p-error" data-testid="company-telephone-number-error">
    {{ val.telephoneNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  telephoneNumber: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "update:telephoneNumber", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  telephoneNumber: {
    required,
  },
};

const val = useVuelidate(rules, props);

const telephoneNo = ref<string>(props.telephoneNumber ?? "");
watch(
  () => props.telephoneNumber,
  (value) => (telephoneNo.value = value ?? ""),
);
const updateTelephoneNumber = () => {
  emit("update:telephoneNumber", telephoneNo.value.replace(/[\s_]+/g, ""));
};
</script>
