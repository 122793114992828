<template>
  <EmployeeHeader
    :edit-mode="true"
    :title="title"
    :has-updates="hasUpdates"
    :is-saving="isSaving"
    @on-delete="onDeleteEmployee"
    @on-commit="onSave"
    @on-cancel="routeToEmployeeSearch"
  />

  <div class="c-page-content mt-1 ml-5 pr-1">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <FirstName ref="employeeFirstnameRef" v-model:first-name="employee.firstName" />
              </div>
              <div class="mb-4">
                <LastName v-model:last-name="employee.lastName" />
              </div>
              <div class="mb-4 md:mb-0">
                <Initials v-model:initials="employee.initials" />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Email v-model:email="employee.email" />
              </div>
              <div class="mb-4">
                <Language v-model:language-code="employee.languageCode" />
              </div>
              <div class="mb-4 md:mb-0">
                <PhoneNumber v-model:phone-number="employee.phoneNumber" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="mb-4">
                <Role v-model:role="selectedRole" :role-options="roleOptions" />
              </div>

              <div class="mb-4">
                <EmployeeState v-model:employee-state="employee.employeeState" />
              </div>

              <div class="grid grid-cols-12 gap-4 mb-4 md:mb-0">
                <div class="col-span-12 lg:col-span-6 lg:pr-4">
                  <CumulusDatePicker
                    :id="'start-date'"
                    v-model:date="employee.startDate"
                    :label="t('employee.start-date')"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6">
                  <CumulusDatePicker
                    :id="'deactivation-date'"
                    v-model:date="employee.deactivationDate"
                    :label="t('employee.deactivation-date')"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-4">
      <div class="col-span-12 lg:col-span-8 mb-6">
        <Panel
          id="prefrencesPanel"
          :header="t('employee.panels.prefrence')"
          toggleable
          class="mb-4 min-h-60"
          data-testid="prefrences-panel"
          :collapsed="panelCollapsedPrefrence"
          :pt:header:on-click="() => (panelCollapsedPrefrence = !panelCollapsedPrefrence)"
        >
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Clients v-model:clients="employee.clients" :client-options="clientOptions" />
              </div>
              <div class="mb-4 md:mb-0">
                <DefaultClient
                  v-model:default-client-id="employee.defaultClientId"
                  :client-options="clientOptions.filter((client) => employee.clients.includes(client.id))"
                />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div class="mb-4 md:mb-0">
                <DefualtWarehouse
                  v-model:default-warehouse-id="employee.warehouseId"
                  :warehouse-options="warehouseOptions"
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Panel
          id="themePanel"
          :header="t('employee.panels.theme')"
          toggleable
          :collapsed="panelCollapsedTheme"
          data-testid="theme-panel"
          :pt:header:on-click="() => (panelCollapsedTheme = !panelCollapsedTheme)"
        >
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="mb-4">
                <DataTableLayout v-model:data-tabel-style="dataTableStyleComputed" />
              </div>

              <div class="mb-4">
                <InputLayout v-model:input-style="inputLayoutComputed" />
              </div>
              <div class="mb-4 md:mb-0">
                <MenuLayout v-model:menu-style="menuStyleComputed" />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>

  <div v-if="loadFailed">
    <Card>
      <template #content
        >{{ t("common.error-load", { entity: t("employee.employee").toLowerCase(), errorReason: errorReason }) }}
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { ShortcutAction, useShortcut } from "@cumulus/components";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";
import { useEmployee } from "@/repositories/employee/EmployeeService";
import { Employee } from "@/repositories/employee/model/Employee";
import { type Client } from "@/repositories/employee/client/model/Client";
import { Role as RoleModel } from "@/repositories/employee/role/model/Role";
import { type Warehouse } from "@/repositories/employee/model/Warehouse";
import { useAuth, User, DataTableStyle, InputStyle, MenuStyle } from "@cumulus/event-bus";

const { getEmployee, updateEmployee, deleteEmployee, getClients, getWarehouses } = useEmployee();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const employee = ref<Employee>(new Employee());
const initialEmployee = ref<Employee | null>(null);
const val = useValidate();
const toast = useCumulusToast(useToast());
const isLoading = ref(true);
const loadFailed = ref(false);
const errorReason = ref("");
const isSaving = ref(false);
const confirmedDiscard = ref(false);

const panelCollapsedPrefrence = ref(false);
const panelCollapsedTheme = ref(false);

const routeToEmployeeSearch = () => {
  confirmedDiscard.value = true;
  router.push({ name: "employee-search", query: { reload: "true" } });
};

const { getUser, isAuthenticated } = useAuth();

const selectedRole = computed<string>({
  get: () => (employee.value.roles.length > 0 ? employee.value.roles[0] : ""),
  set: (value) => {
    employee.value.roles = [value];
  },
});

const dataTableStyleComputed = computed<DataTableStyle>({
  get: () => employee.value.preferences.dataTableStyle,
  set: (value: DataTableStyle) => {
    employee.value.preferences.dataTableStyle = value;

    getUser().then((loginUser) => {
      if (employee.value.id === loginUser.getEmployee().id) {
        loginUser.setPreference(employee.value.preferences);
        reloadBodyCss(loginUser);
      }
    });
  },
});

const inputLayoutComputed = computed<InputStyle>({
  get: () => employee.value.preferences.inputStyle,
  set: (value: InputStyle) => {
    employee.value.preferences.inputStyle = value;
    getUser().then((loginUser) => {
      if (employee.value.id === loginUser.getEmployee().id) {
        loginUser.setPreference(employee.value.preferences);
        reloadBodyCss(loginUser);
      }
    });
  },
});

const menuStyleComputed = computed<MenuStyle>({
  get: () => employee.value.preferences.menuStyle,
  set: (value: MenuStyle) => {
    employee.value.preferences.menuStyle = value;
    getUser().then((loginUser) => {
      if (employee.value.id === loginUser.getEmployee().id) {
        loginUser.setPreference(employee.value.preferences);
        reloadBodyCss(loginUser);
      }
    });
  },
});

const reloadBodyCss = (user: User) => {
  document.body.className = user.getBodyCssClass(isAuthenticated.value);
};

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  isSaving.value = true;
  try {
    await updateEmployee(employee.value);

    toast.add({
      severity: "success",
      summary: t("employee.toast.edit.summary"),
      detail: t("employee.toast.edit.detail", { email: employee.value.email }),
      closable: true,
    });

    routeToEmployeeSearch();
  } finally {
    isSaving.value = false;
  }
};

const onDeleteEmployee = async () => {
  isSaving.value = true;
  try {
    await deleteEmployee(employee.value.id);

    toast.add({
      severity: "success",
      summary: t("employee.toast.delete.success.summary"),
      closable: true,
    });

    routeToEmployeeSearch();
  } finally {
    isSaving.value = false;
  }
};

const fetchEmployeeById = async () => {
  try {
    const id = route.params.id as string;

    isLoading.value = true;
    employee.value = await getEmployee(id);
    initialEmployee.value = cloneDeep(employee.value);
    focusFirstInput();
  } finally {
    isLoading.value = false;
  }
};

const clientOptions = ref<Client[]>([]);
const fetchClientOptions = async () => {
  clientOptions.value = await getClients();
};

const roleOptions = ref<RoleModel[]>([]);
const fetchRoleOptions = async () => {
  roleOptions.value = [
    new RoleModel("reader", t(`common.role.reader`)),
    new RoleModel("contributor", t(`common.role.contributor`)),
    new RoleModel("admin", t(`common.role.admin`)),
  ];
};

const warehouseOptions = ref<Warehouse[]>([]);
const fetchWarehouseOptions = async () => {
  warehouseOptions.value = await getWarehouses();
};

const employeeFirstnameRef = ref();
const focusFirstInput = () => {
  employeeFirstnameRef.value?.focus();
};

const hasUpdates = computed(() => {
  return !isEqual(employee.value, initialEmployee.value);
});

onMounted(() => {
  Promise.all([fetchEmployeeById(), fetchWarehouseOptions(), fetchRoleOptions(), fetchClientOptions()]);
});

useShortcut(ShortcutAction.save, onSave);

const title = computed(() => {
  if (employee.value) {
    return employee.value.firstName + " " + employee.value.lastName;
  }

  return "";
});

useUnsavedChanges(hasUpdates);
</script>
