<template>
  <ClientHeader
    :edit-mode="false"
    :has-updates="hasUpdates"
    :is-saving="isSaving"
    :client="client"
    :is-new-client="true"
    @on-commit="onCreate"
    @on-cancel="routeToClientSearch"
  />

  <div class="m-4">
    <Message
      v-if="showMessage"
      :severity="msg.severity"
      :life="msg.life"
      :sticky="msg.sticky"
      :closable="msg.closeable"
      >{{ msg.content }}</Message
    >
    <div class="grid grid-cols-12 mb-4">
      <Card class="col-span-12 lg:col-span-8 mr-4 pb-2">
        <template #content>
          <div class="mx-6">
            <div class="grid grid-cols-12">
              <div class="col-span-12 lg:col-span-10">
                <div class="col-span-12 lg:col-span-5 mr-8">
                  <div class="grid grid-cols-12 gap-4 mt-2">
                    <div class="col-span-12 lg:col-span-6">
                      <Name v-model:name="client.name" />
                    </div>
                    <div class="col-span-12 lg:col-span-6">
                      <BusinessNumber v-model:business-number="client.organizationNumber" />
                    </div>
                  </div>
                </div>
                <div class="col-span-12 lg:col-span-5 mr-8 mt-6">
                  <div class="grid grid-cols-12 gap-4">
                    <div class="col-span-12 lg:col-span-4">
                      <Email v-model:email="client.email" />
                    </div>
                    <div class="col-span-12 lg:col-span-2">
                      <PhoneNumber v-model:phone-number="client.phoneNumber" />
                    </div>
                    <div class="col-span-12 lg:col-span-6">
                      <ClientStatus v-model:client-state="client.clientState" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12 lg:col-span-2 mx-4">
                <LogoUpload :logo="client.logoBase64" @update:logo="client.logoBase64 = $event" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4 mr-4 pb-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-5">
            <div class="col-span-12">
              <div class="mt-4 mb-6">
                <Country v-model:country="client.countryIso" :country-options="countryOptions" />
              </div>

              <div>
                <Currency v-model:currency="client.currencyIso" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="grid grid-cols-12 mb-4">
      <Card class="col-span-12 lg:col-span-8 mr-4 pb-4">
        <template #content>
          <div class="col-span-12 lg:col-span-8 mx-6">
            <div class="grid grid-cols-12">
              <label for="client-address" class="pb-2">{{ t("client.client-address") }}</label>
              <Address
                v-model:address="client.address"
                :country-options="countryOptions"
                :address-type="clientAddress"
              />
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4 mx-4">
            <div class="col-span-12">
              <div class="mt-2 mb-6">
                <label for="bank-info" class="pb-2">{{ t("client.banking.header") }}</label>
                <Banking v-model:banking="client.banking" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import isEqual from "lodash.isequal";
import { Client } from "@/repositories/client/model/Client";
import { type Message as MessageModel } from "@/repositories/client/model/message/Message";
import { MessageSeverityType } from "@/repositories/client/model/message/MessageSeverityType";
import { useCountry } from "@/repositories/country/CountryService";
import { type Country as CountryModel } from "@/repositories/country/model/Country";
import { useClient } from "@/repositories/client/ClientService";
import { useUnsavedChanges } from "@cumulus/components";

const { t } = useI18n();
const router = useRouter();
const showMessage = ref(false);
const msg = ref<MessageModel>({} as MessageModel);

const countryOptions = ref<CountryModel[]>([]);
const isSaving = ref(false);
const client = ref<Client>(new Client());
const initialClient = ref<Client>(new Client());
const clientAddress = ref<string>("client-address");
const confirmedDiscard = ref(false);
const val = useValidate();

const routeToClientSearch = () => {
  confirmedDiscard.value = true;
  router.push({ name: "client-search" });
};

// TODO: Only a demo, might be moved to Layout MF later
const addMessage = (content: string, severity: MessageSeverityType) => {
  showMessage.value = true;

  msg.value.content = content;
  msg.value.life = 3000;
  msg.value.severity = severity;
  msg.value.sticky = true;
  msg.value.closeable = false;

  setTimeout(() => {
    showMessage.value = false;
  }, 3000);
};

const { createClient } = useClient();

const onCreate = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    addMessage(t("common.message.validate-error"), MessageSeverityType.warn);
    return;
  }
  isSaving.value = true;

  try {
    await createClient(client.value);

    addMessage(t("client.message.success-add"), MessageSeverityType.success);
    setTimeout(() => {
      routeToClientSearch();
    }, 3000);
  } finally {
    isSaving.value = false;
  }
};

const { getCountries } = useCountry();

const fetchCountryData = async () => {
  countryOptions.value = await getCountries();
};

const hasUpdates = computed(() => {
  return !isEqual(client.value, initialClient.value);
});

onMounted(() => {
  fetchCountryData();
});

useUnsavedChanges(hasUpdates);
</script>
