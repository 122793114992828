<template>
  <FloatLabel variant="on">
    <Select
      id="input-style"
      v-model="inputStyle"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.input-style').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-test-id="input-style"
      pt:list:data-testid="input-style-list"
      class="w-full"
      option-label="name"
      option-value="value"
      :options="dropdownOptions"
    />

    <label for="input-style">
      {{ t(`employee.input-style`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { InputStyle } from "@cumulus/event-bus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const inputStyle = defineModel<string>("inputStyle");

const { t } = useI18n();

const dropdownOptions = computed(() => {
  const keys = Object.keys(InputStyle);
  return keys.map((key) => {
    return { name: t(`employee.input-style-type.${key.toLowerCase()}`), value: key };
  });
});
</script>
