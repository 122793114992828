<template>
  <FloatLabel variant="on">
    <InputMask
      id="company-organization-number"
      v-model="organizationNo"
      mask="999 999 999"
      data-testid="company-organization-number"
      class="inputfield w-full"
      :invalid="val.organizationNumber.$error"
      :disabled="disabled"
      @blur="updateOrganizationNumber"
    />
    <label for="company-organization-number">{{ t("company.organization-number") }}</label>
  </FloatLabel>
  <small v-if="val.organizationNumber.$error" class="p-error" data-testid="company-organization-number-error">
    {{ val.organizationNumber.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  organizationNumber: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "update:organizationNumber", value: string): void;
}>();

const { t } = useI18n();

const rules = {
  organizationNumber: {
    required,
  },
};

const val = useVuelidate(rules, props);

const organizationNo = ref<string>(props.organizationNumber ?? "");
watch(
  () => props.organizationNumber,
  (value) => (organizationNo.value = value ?? ""),
);
const updateOrganizationNumber = () => {
  emit("update:organizationNumber", organizationNo.value.replace(/[\s_]+/g, ""));
};
</script>
