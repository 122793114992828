<template>
  <FloatLabel variant="on">
    <Select
      id="client-currency"
      v-model="currencyComputed"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('client.currency').toLowerCase() }),

        showDelay: 1000,
        hideDelay: 300,
      }"
      :options="currencies"
      option-label="iso"
      option-value="iso"
      data-testid="client-currency"
      aria-describedby="client-currency-help"
      class="w-full"
      :class="{ 'p-invalid': val.currency.$error }"
      pt:list:data-testid="client-currency-list"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    />
    <label for="client-currency" class="c-under-construction">
      {{ t(`client.currency`) }}
    </label>
  </FloatLabel>
  <small v-if="val.currency.$error" data-testid="client-currency-error" class="p-error">{{
    val.currency.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { currencyRepository } from "@/repositories/currency/CurrencyRepository";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { type Currency } from "@/repositories/currency/model/Currency";

const props = defineProps<{
  currency: string;
}>();

const emit = defineEmits<{
  (e: "update:currency", value: string): void;
}>();

const { t } = useI18n();

const isActive = ref(false);

const currencyComputed = computed<string>({
  get: () => {
    return props.currency;
  },
  set: (value) => {
    emit("update:currency", value);
  },
});

const currencies = ref<Currency[]>([]);
const fetchCurrencies = async () => {
  currencies.value = await currencyRepository.getAll();
};
onMounted(fetchCurrencies);

const rules = {
  currency: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
