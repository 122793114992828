<template>
  <FloatLabel variant="on">
    <Select
      id="employee-state"
      v-model="employeeStateComputed"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.employee-state').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      data-testid="employee-state"
      pt:list:data-testid="employee-state-list"
      class="w-full"
      option-label="label"
      option-value="value"
      :options="options"
    />

    <label for="employee-state">
      {{ t("employee.employee-state") }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { EmployeeState } from "@/repositories/employee/model/EmployeeState";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  employeeState: EmployeeState;
}>();

const emit = defineEmits<{
  (e: "update:employeeState", value: string): void;
}>();

const options = ref<Record<string, string>[]>([]);
const { t } = useI18n();

const employeeStateComputed = computed<string>({
  get: () => {
    return props.employeeState ?? EmployeeState.Active;
  },
  set: (value) => {
    emit("update:employeeState", value);
  },
});

const fetchEmployeeStatus = () => {
  for (const state in EmployeeState) {
    options.value.push({
      label: t(`employee.status.${state.toString().toLowerCase()}`),
      value: state,
    });
  }
};

onMounted(fetchEmployeeStatus);
</script>
