<template>
  <div class="c-import">
    <div class="mt-6">
      <ImportToolbar @onClose="routeToDashboard" />
    </div>

    <Card>
      <template #content>
        <div class="col-span-12 h-auto">
          <div class="col-span-12 md:col-span-12 lg:col-span-6">
            <span class="text-lg font-bold mb-2">{{ t(`import.company-name`, { companyName: companyName }) }} </span>
          </div>
          <div class="col-span-12 mt-6">
            <label class="font-normal italic">
              {{ t("import.important-requirement") }}
              <a href="/freight/methods" class="underline"> {{ t("import.shipping-method") }}</a>
            </label>
          </div>
          <div class="col-span-12 ml-10">
            <div v-for="type in importTypes" :key="type" class="flex place-items-center mt-10">
              <FileUpload
                mode="basic"
                accept=".csv"
                :chooseLabel="getChooseLabel(type)"
                class="text-left px-10 min-w-80"
                customUpload
                @uploader="uploader($event as unknown as FileUploadUploadEvent, type)"
                :disabled="isDisabled(type)"
                :auto="true"
                :chooseIcon="'pi pi-book'"
              >
              </FileUpload>
              <div class="inline-block ml-4 font-bold">
                <div v-if="messages[type]?.status === 400">
                  <div v-for="(message, index) in messages[type]?.message" :key="index">
                    <span>{{ message }}</span>
                  </div>
                </div>
                <div v-else-if="messages[type]?.status === 202 || messages[type]?.status === 200">
                  <span>{{ t("import.success", { importType: type }) }}</span>
                </div>
                <div v-show="uploadInProgress && type === importType" class="mr-6">
                  {{ t("import.importing") }}
                </div>
                <div v-if="uploadInProgress && type === importType">
                  <ProgressSpinner style="width: 3rem; height: 3rem"></ProgressSpinner>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { FileUploadUploadEvent } from "primevue/fileupload";

import ImportToolbar from "./components/ImportToolbar.vue";

import { useCompany } from "@/repositories/company/CompanyService";
import { useImport } from "@/repositories/import/ImportService";
import { ImportType } from "@/repositories/import/model/ImportType";
import { ImportResultMessage } from "@/repositories/import/model/ImportResultMessage";

const { t } = useI18n();

const { getCompany } = useCompany();
const { importCsvFile } = useImport();

const router = useRouter();

const companyName = ref<string>("");
const importType = ref<ImportType>(ImportType.Supplier);
const messages = reactive<Record<ImportType, ImportResultMessage>>({
  [ImportType.Supplier]: new ImportResultMessage(),
  [ImportType.B2BCustomer]: new ImportResultMessage(),
  [ImportType.Products]: new ImportResultMessage(),
  [ImportType.Undefined]: new ImportResultMessage(),
});

const loadCompany = async () => {
  companyName.value = (await getCompany()).companyName;
};
onMounted(loadCompany);

const routeToDashboard = () => {
  router.push("/");
};
const uploadInProgress = ref(false);
const uploader = async (event: FileUploadUploadEvent, type: ImportType) => {
  const files = event.files;
  importType.value = type;

  if (files !== undefined && files !== null) {
    uploadInProgress.value = true;

    try {
      for (const file of files as File[]) {
        messages[type] = await importCsvFile(file, type);
      }
    } catch (error: ImportResultMessage | unknown) {
      messages[type] = error as ImportResultMessage;
    } finally {
      uploadInProgress.value = false;
    }
  }
};

const importTypes = [ImportType.Supplier, ImportType.B2BCustomer, ImportType.Products];

const getChooseLabel = (type: ImportType) => {
  switch (type) {
    case ImportType.Supplier:
      return t("import.file.suppliers");
    case ImportType.B2BCustomer:
      return t("import.file.customers");
    case ImportType.Products:
      return t("import.file.products");
    default:
      return "";
  }
};

const isDisabled = (type: ImportType) => {
  if (type === ImportType.Supplier) {
    return uploadInProgress.value;
  } else if (type === ImportType.B2BCustomer) {
    return !(messages[ImportType.Supplier]?.status === 202);
  } else if (type === ImportType.Products) {
    return !(messages[ImportType.B2BCustomer]?.status === 202);
  }
  return true;
};
</script>

<style lang="scss">
.c-import {
  margin: var(--default-content-margin);
}

.p-fileupload-choose .p-button-icon-left {
  margin-right: 2rem;
}
</style>
