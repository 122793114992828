<template>
  <FloatLabel variant="on">
    <Select
      id="client-country"
      v-model="countryComputed"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('client.country').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      :options="countryOptionsComputed"
      option-value="iso"
      option-label="name"
      data-testid="client-country"
      class="w-full"
      :class="{ 'p-invalid': val.country.$error }"
      pt:list:data-testid="client-country-list"
      @show="isActive = true"
      @hide="isActive = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    />
    <label for="client-country">
      {{ t(`client.country`) }}
    </label>
  </FloatLabel>
  <small v-if="val.country.$error" data-testid="client-country-error" class="p-error">{{
    val.country.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { type Country } from "@/repositories/country/model/Country";
import useVuelidate from "@vuelidate/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  country: string;
  countryOptions: Country[];
}>();

const emit = defineEmits<{
  (e: "update:country", value: string | null): void;
}>();

const { t } = useI18n();

const isActive = ref(false);

const countryOptionsComputed = computed<Country[]>(() => {
  return props.countryOptions ?? [];
});

const countryComputed = computed<string>({
  get: () => {
    return props.country;
  },
  set: (value) => {
    emit("update:country", value);
  },
});

const rules = {
  country: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
