<template>
  <EmployeeHeader
    :edit-mode="false"
    :has-updates="hasUpdates"
    :is-saving="isSaving"
    @on-commit="onCreate"
    @on-cancel="routeToEmployeeSearch"
  />

  <Message v-for="employeeCre of employeesCreated" :key="employeeCre.id" severity="info"
    >{{ t("employee.created-message")
    }}<a class="employee-created-link" :href="`/system-data/employee/${employeeCre.id}`" :closable="true">{{
      employeeCre.firstName + " " + employeeCre.lastName
    }}</a></Message
  >

  <div class="c-page-content mt-1 ml-5 pr-1">
    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <FirstName ref="employeeFirstnameRef" v-model:first-name="employee.firstName" />
              </div>
              <div class="mb-4">
                <LastName v-model:last-name="employee.lastName" />
              </div>
              <div class="mb-4 md:mb-0">
                <Initials v-model:initials="employee.initials" />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div class="mb-4">
                <Email v-model:email="employee.email" />
              </div>
              <div class="mb-4">
                <Language v-model:language-code="employee.languageCode" />
              </div>
              <div class="mb-4 md:mb-0">
                <PhoneNumber v-model:phone-number="employee.phoneNumber" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="mb-4">
                <Role v-model:role="selectedRole" :role-options="roleOptions" />
              </div>

              <div class="mb-4">
                <EmployeeState v-model:employee-state="employee.employeeState" />
              </div>

              <div class="grid grid-cols-12 gap-4 mb-4 md:mb-0">
                <div class="col-span-12 lg:col-span-6 lg:pr-4">
                  <CumulusDatePicker
                    :id="'start-date'"
                    v-model:date="employee.startDate"
                    :label="t('employee.start-date')"
                  />
                </div>
                <div class="col-span-12 lg:col-span-6">
                  <CumulusDatePicker
                    :id="'deactivation-date'"
                    v-model:date="employee.deactivationDate"
                    :label="t('employee.deactivation-date')"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <div class="grid grid-cols-12 gap-4 mb-4">
      <div class="col-span-12 lg:col-span-8 mb-6">
        <Panel
          id="prefrencesPanel"
          :header="t('employee.panels.prefrence')"
          toggleable
          class="mb-4 min-h-60"
          data-testid="prefrences-panel"
          :collapsed="panelCollapsedPrefrence"
          :pt:header:on-click="() => (panelCollapsedPrefrence = !panelCollapsedPrefrence)"
        >
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-6 md:mr-4">
              <div class="mb-4">
                <Clients v-model:clients="employee.clients" :client-options="clientOptions" />
              </div>
              <div class="mb-4 md:mb-0">
                <DefaultClient
                  v-model:default-client-id="employee.defaultClientId"
                  :client-options="clientOptions.filter((client) => employee.clients.includes(client.id))"
                />
              </div>
            </div>
            <div class="col-span-12 md:col-span-6">
              <div class="mb-4 md:mb-0">
                <DefualtWarehouse
                  v-model:default-warehouse-id="employee.warehouseId"
                  :warehouse-options="warehouseOptions"
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Panel
          id="themePanel"
          :header="t('employee.panels.theme')"
          toggleable
          :collapsed="panelCollapsedTheme"
          data-testid="theme-panel"
          :pt:header:on-click="() => (panelCollapsedTheme = !panelCollapsedTheme)"
        >
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="mb-4">
                <DataTableLayout v-model:data-tabel-style="employee.preferences.dataTableStyle" />
              </div>

              <div class="mb-4">
                <InputLayout v-model:input-style="employee.preferences.inputStyle" />
              </div>
              <div class="mb-4 md:mb-0">
                <MenuLayout v-model:menu-style="employee.preferences.menuStyle" />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast, useUnsavedChanges } from "@cumulus/components";
import { ShortcutAction, useShortcut } from "@cumulus/components";
import { CumulusDatePicker } from "@cumulus/components";
import isEqual from "lodash.isequal";
import { NewEmployee } from "@/repositories/employee/model/NewEmployee";
import { type Client } from "@/repositories/employee/client/model/Client";
import { Role as RoleModel } from "@/repositories/employee/role/model/Role";
import { type Warehouse } from "@/repositories/employee/model/Warehouse";
import { useEmployee } from "@/repositories/employee/EmployeeService";

const { createEmployee, getClients, getWarehouses } = useEmployee();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const router = useRouter();
const employee = ref(new NewEmployee());
const initialEmployee = ref<NewEmployee>(new NewEmployee());
const isSaving = ref(false);
const employeesCreated = ref<NewEmployee[]>([]);
const confirmedDiscard = ref(false);

const panelCollapsedPrefrence = ref(false);
const panelCollapsedTheme = ref(false);

onMounted(() => {
  employee.value.languageCode = "NO";
});

const routeToEmployeeSearch = () => {
  confirmedDiscard.value = true;
  router.push({ name: "employee-search" });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const val = useValidate();

const onCreate = async () => {
  //Wait for onblur functions
  await timeout(300);

  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }
  isSaving.value = true;
  try {
    await createEmployee(employee.value);

    employeesCreated.value.push(employee.value);
    employee.value = new NewEmployee();
    initialEmployee.value = new NewEmployee();
    val.value.$reset();
    window.scrollTo(0, 0);
    focusFirstInput();
  } finally {
    isSaving.value = false;
  }
};

const clientOptions = ref<Client[]>([]);
const fetchClientOptions = async () => {
  clientOptions.value = await getClients();
};

const selectedRole = computed<string>({
  get: () => (employee.value.roles.length > 0 ? employee.value.roles[0] : ""),
  set: (value) => {
    employee.value.roles = [value];
  },
});

const roleOptions = ref<RoleModel[]>([]);
const fetchRoleOptions = async () => {
  roleOptions.value = [
    new RoleModel("reader", t(`common.role.reader`)),
    new RoleModel("contributor", t(`common.role.contributor`)),
    new RoleModel("admin", t(`common.role.admin`)),
  ];
};

const warehouseOptions = ref<Warehouse[]>([]);
const fetchWarehouseOptions = async () => {
  warehouseOptions.value = await getWarehouses();
};

const employeeFirstnameRef = ref();
const focusFirstInput = () => {
  employeeFirstnameRef.value.focus();
};

onMounted(focusFirstInput);

const hasUpdates = computed(() => {
  return !isEqual(employee.value, initialEmployee.value);
});

onMounted(() => {
  Promise.all([focusFirstInput(), fetchWarehouseOptions(), fetchRoleOptions(), fetchClientOptions()]);
});

useShortcut(ShortcutAction.save, onCreate);

useUnsavedChanges(hasUpdates);
</script>
