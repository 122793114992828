<template>
  <ClientToolbar @on-export="emit('onExport')" @clients-lists-refresh="emit('clientsListsRefresh')" />
  <DataTable
    ref="clientDataTable"
    v-model:expanded-rows="expandedRows"
    v-model:selection="selectedClient"
    :value="clients"
    class="c-datatable"
    data-key="id"
    :auto-layout="true"
    responsive-layout="scroll"
    selection-mode="single"
    :loading="loading"
    striped-rows
    sort-field="name"
    :sort-order="1"
    :paginator="true"
    :current-page-report-template="
      t('client.search.current-page', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows="pageSize"
    :rows-per-page-options="[50, 100]"
    data-testid="client-table"
    scrollable
    scroll-height="75vh"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClicked"
  >
    <Column field="registeredDate" :header="t('client.search.header-registered')">
      <template #body="{ data }"> {{ d(data.registeredDate, "short") }}</template>
    </Column>
    <Column
      v-for="(col, index) of columns"
      :key="col.field + '_' + index"
      :field="col.field"
      :header="col.header"
      :sortable="true"
    >
    </Column>
    <template #empty>{{ t("client.search.empty-list") }} </template>
    <template #loading>{{ t("client.search.loading-list") }}</template>
  </DataTable>
</template>
<script setup lang="ts">
import { Client } from "@/repositories/client/model/Client";

import { type DataTableColumn } from "@/repositories/client/model/search/DataTableColumn";
import { type DataTableRowDoubleClickEvent, type DataTableRowSelectEvent } from "primevue/datatable";
import { nextTick, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/components";
import { NIL as emptyUuid } from "uuid";
import ClientToolbar from "@/client/list/ClientToolbar.vue";

const expandedRows = ref([]);
const router = useRouter();
const { t, d } = useI18n();
const selectedClient = ref<Client>(new Client());
const clientDataTable = ref();
const previouslyFocusRow = ref();
const toast = useCumulusToast(useToast());

const props = defineProps<{
  clients: Client[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
}>();

const emit = defineEmits<{
  (e: "update:pageSize", value: number): void;
  (e: "onExport"): void;
  (e: "clientsListsRefresh"): void;
}>();

watch([() => props.clients, () => props.loading], ([clients, loading]) => {
  if (loading === false && clients.length > 0) {
    nextTick(() => {
      focusSearchResult();
    });
  } else {
    clearFocusedRow();
  }
});
const clearFocusedRow = () => {
  previouslyFocusRow.value = null;
  selectedClient.value = new Client();
};

const columns = ref<DataTableColumn[]>([
  { field: "organizationNumber", header: t("client.search.header-business-number") },
  { field: "name", header: t("client.search.header-name") },
  { field: "phoneNumber", header: t("client.search.header-phone") },
  { field: "email", header: t("client.search.header-email") },
  { field: "countryIso", header: t("client.search.header-country") },
  { field: "clientState", header: t("client.search.header-client-status") },
] as DataTableColumn[]);

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  setFocusedRow(event.index);
  openClient(event.data.id);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  setFocusedRow(event.index);
  openClient(event.data.id);
};
const openClient = (clientId: string) => {
  clientId != null && clientId !== emptyUuid
    ? router.push({ name: "client-edit", params: { id: clientId } })
    : toast.add({
        severity: "warn",
        summary: t("client.toast.open-client-failed"),
        detail: t("client.toast.no-client-selected"),
        closable: true,
      });
};

const focusSearchResult = () => {
  if (props.clients.length > 0 && clientDataTable.value) {
    if (previouslyFocusRow.value) {
      nextTick(() => {
        previouslyFocusRow.value.focus();
      });
    } else {
      clientDataTable.value?.$el.querySelector("tbody tr:first-of-type").focus();
    }
  }
};

const setFocusedRow = (index: number) => {
  const pageIndex = (props.page - 1) * props.pageSize;
  const rowIndex = index - pageIndex;
  previouslyFocusRow.value = clientDataTable.value.$el.querySelector(`tbody tr:nth-child(${rowIndex + 1})`);
};

const exportCSV = () => {
  clientDataTable.value.exportCSV();
};
defineExpose({ exportCSV });
</script>
<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
