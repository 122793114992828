<template>
  <div class="flex flex-wrap border-b pb-2 mb-2">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button data-testid="add-client-btn" variant="text" size="small" @click="router.push({ name: 'client-add' })">
        <i class="pi pi-plus c-success-button c-circular-icon"></i>
        <span class="px-2">{{ t("common.add") }}</span>
      </Button>
      <Button class="mr-4" variant="text" size="small" @click="$emit('onExport')">
        <i class="pi pi-upload c-default-button c-circular-icon"></i>
        <span class="px-2">{{ t("client.export") }}</span>
      </Button>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5">
      <Button
        v-tooltip.bottom="{
          value: t('common.toolbar.refresh'),
          showDelay: 1000,
          hideDelay: 300,
        }"
        data-testid="refresh-button"
        severity="secondary"
        text
        @click="emit('clientsListsRefresh')"
      >
        <i class="pi pi-refresh"></i>
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

const router = useRouter();

const { t } = useI18n();

const emit = defineEmits<{
  (e: "onExport"): void;
  (e: "clientsListsRefresh"): void;
}>();
</script>
<style scoped lang="scss">
:deep(.p-button-icon, .pi-upload) {
  background-color: var(--default-btn-bg);
  padding: 0.3rem;
  border-radius: 50%;
  color: var(--primary-color-text);
}
</style>
