<template>
  <div class="field col-span-12 md:col-span-12">
    <FloatLabel variant="on">
      <Textarea
        id="address-lines"
        v-model="addressLinesComputed"
        type="text"
        rows="1"
        class="inputfield w-full"
        :class="{ 'p-invalid': val.addressLines.$error }"
        :data-testid="`${addressType}-address-lines`"
        :disabled="disabled"
        @change="onChangeComplete"
      />
      <label for="address-lines">{{ t("common.address.address-lines") }}</label>
    </FloatLabel>
    <small v-if="val.addressLines.$error" :id="`${addressType}-address-lines-error`" class="p-error">
      {{ val.addressLines.$errors[0].$message }}
    </small>
  </div>
  <div class="field col-span-12 md:col-span-12">
    <FloatLabel variant="on">
      <Select
        id="address-country"
        v-model="selectedCountryComputed"
        :options="countryOptions"
        option-label="name"
        option-value="iso"
        class="inputfield w-full"
        :class="{ 'p-invalid': val.countryIso.$error }"
        :data-testid="`${addressType}-country`"
        :disabled="disabled"
        :pt:list:data-testid="`${addressType}-country-list`"
        @change="onChangeComplete"
      />
      <label for="address-country">{{ t("common.address.country") }}</label>
    </FloatLabel>
    <small v-if="val.countryIso.$error" :id="`${addressType}-country-error`" class="p-error">{{
      val.countryIso.$errors[0].$message
    }}</small>
  </div>

  <div class="field col-span-12 md:col-span-12">
    <div class="formgrid grid grid-cols-12 gap-8">
      <div class="field col-span-12 md:col-span-4">
        <FloatLabel variant="on">
          <InputText
            id="address-postal-code"
            v-model="addressComputed.postalCode"
            maxlength="4"
            type="text"
            class="inputfield w-full"
            :class="{ 'p-invalid': val.postalCode.$error }"
            :data-testid="`${addressType}-postal-code`"
            :disabled="disabled"
            @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
          />
          <label for="address-postal-code">{{ t("common.address.postal-code-city") }}</label>
        </FloatLabel>
        <small v-if="val.postalCode.$error" :id="`${addressType}-postal-code-error`" class="p-error">{{
          val.postalCode.$errors[0].$message
        }}</small>
      </div>
      <div class="field col-span-12 md:col-span-8">
        <FloatLabel variant="on">
          <InputText
            id="address-city"
            v-model="addressComputed.city"
            type="text"
            class="inputfield w-full"
            :class="{ 'p-invalid': val.city.$error }"
            :data-testid="`${addressType}-city`"
            :disabled="disabled"
            :aria-label="t('common.address.city')"
            @change="onChangeComplete"
          />
          <label for="address-city">{{ t("common.address.city") }}</label>
        </FloatLabel>
        <small v-if="val.city.$error" :id="`${addressType}-city-error`" class="p-error">{{
          val.city.$errors[0].$message
        }}</small>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { type Address } from "@/repositories/company/model/Address";
import { type Country } from "@/repositories/country/model/Country";
import useVuelidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import cloneDeep from "lodash.clonedeep";
import { useCountry } from "@/repositories/country/CountryService";
import Select from "primevue/select";

const { t } = useI18n();

const props = defineProps<{
  address: Address;
  countryOptions: Country[];
  addressType: string;
  disabled: boolean;
}>();

const emit = defineEmits<{
  (e: "setAddress", value: Address): void;
}>();

const addressComputed = computed<Address>(() => {
  return props.address ? cloneDeep(props.address) : ({} as Address);
});

const addressLinesComputed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines ? addressComputed.value.addressLines.join("\n") : "";
  },
  set: (val: string) => {
    addressComputed.value.addressLines = val.toString().replace(/\r\n/g, "\n").split("\n");
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    return addressComputed.value?.countryIso ?? "";
  },
  set: (value) => {
    if (value !== undefined) {
      addressComputed.value.countryIso = value;
    }
  },
});

const onChangeComplete = () => {
  emit("setAddress", addressComputed.value);
};

const { getCities } = useCountry();

const onPostalCodeChange = async (code: string) => {
  if (code === undefined || code.length < 3) {
    return;
  }
  const response = await getCities(code);
  addressComputed.value.city = response[0].city;
  onChangeComplete();
};

const rules = {
  addressLines: {
    required,
    $each: helpers.withMessage(t("common.address.address-lines-error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
  countryIso: {
    required,
  },
};

const val = useVuelidate(rules, addressComputed);
</script>
