<template>
  <FloatLabel variant="on">
    <Select
      id="header-style"
      dataTestId="header-style"
      pt:list:data-testid="header-style-list"
      class="w-full"
      optionLabel="name"
      optionValue="value"
      :options="dropdownOptions"
      v-model="headerStyle"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('employee.header-style').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="header-style">
      {{ t(`employee.header-style`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { HeaderStyle } from "@cumulus/event-bus";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const headerStyle = defineModel<string>("headerStyle");

const { t } = useI18n();

const dropdownOptions = computed(() => {
  const keys = Object.keys(HeaderStyle);
  return keys.map((key) => {
    return { name: t(`employee.header-style-type.${key.toLowerCase()}`), value: key };
  });
});
</script>
