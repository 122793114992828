<template>
  <Dialog
    id="set-default-printer-dialog"
    v-model:visible="visible"
    :header="t('printing.set-default-printer')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="w-4/12"
  >
    <div class="formgrid grid grid-cols-12 gap-4 mb-6">
      <div class="field col-span-12">
        <label for="printer">{{ t("printing.printer") }}</label>
        <Select
          id="printer"
          v-model="defaultPrinterRef.printerName"
          :options="printers"
          class="w-full"
          option-label="name"
          option-value="name"
          data-testid="default-printer-dropdown"
          :placeholder="t('placeholder.select', { property: t('printing.printer').toLowerCase() })"
          pt:list:data-testid="default-printer-dropdown-list"
        />
        <small v-if="val.printerName.$error" class="p-error" data-testid="printing-name-error">
          {{ val.printerName.$errors[0].$message }}
        </small>
      </div>

      <div class="field col-span-12">
        <label for="printer">{{ t("printing.document-type.label") }}</label>
        <span class="block w-full">{{
          t(`printing.document-type.${defaultPrinterInfo.documentType.toLowerCase()}`)
        }}</span>
      </div>
      <div class="field col-span-12">
        <label for="printer">{{ t("printing.location") }}</label>
        <InputText
          id="location"
          v-model="defaultPrinterRef.location"
          data-testid="default-printer-location-input"
          class="inputfield w-full"
        />
        <small v-if="val.location.$error" class="p-error" data-testid="printing-location-error">
          {{ val.location.$errors[0].$message }}
        </small>
      </div>
      <div class="field col-span-12">
        <label for="printer">{{ t("printing.description") }}</label>
        <InputText
          id="location"
          v-model="defaultPrinterRef.description"
          data-testid="default-printer-description-input"
          class="inputfield w-full"
        />
        <small v-if="val.location.$error" class="p-error" data-testid="printing-description-error">
          {{ val.location.$errors[0].$message }}
        </small>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-center gap-4">
        <Button :label="t('common.cancel')" data-testid="btn-cancel" severity="cancel" text @click="visible = false">
          <i class="pi pi-times"></i>
          <span class="px-4 font-bold">{{ t("common.cancel") }}</span></Button
        >

        <Button type="button" class="c-delete-button" data-testid="btn-delete" @click="onDeleteDefaultPrinter">
          <i class="pi pi-trash"></i>
          <span class="px-4">{{ t("common.delete") }}</span>
        </Button>

        <Button
          type="button"
          data-testid="btn-save-default-printer"
          class="c-success-button"
          :disabled="isSaving"
          @click="saveDefaultPrinter"
        >
          <ProgressSpinner v-if="isSaving" class="w-8 h-8" />
          <i v-else class="pi pi-check"></i>
          <span class="px-4">{{ t("common.save") }}</span>
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Printer } from "../models/Printer";
import { DefaultPrinterInfo } from "../models/DefaultPrinterInfo";
import { useDocumentService } from "../api/DocumentService";
import { useCumulusToast } from "@cumulus/components";
import { useToast } from "primevue/usetoast";
import cloneDeep from "lodash.clonedeep";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { setDefaultPrinter, deleteDefaultPrinter } = useDocumentService();

const props = defineProps<{
  visibleDialog: boolean;
  defaultPrinterInfo: DefaultPrinterInfo;
  printers: Printer[];
}>();

const emit = defineEmits<{
  (e: "update:visibleDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.visibleDialog,
  set: (value) => emit("update:visibleDialog", value),
});

const defaultPrinterRef = ref<DefaultPrinterInfo>(cloneDeep(props.defaultPrinterInfo));

const isSaving = ref<boolean>(false);

const onDeleteDefaultPrinter = async () => {
  try {
    isSaving.value = true;
    await deleteDefaultPrinter(defaultPrinterRef.value.documentType);

    toast.add({
      severity: "success",
      summary: t("printing.default-printer-deleted"),
    });

    emit("update:visibleDialog", false);
  } finally {
    isSaving.value = false;
  }
};

const saveDefaultPrinter = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  try {
    isSaving.value = true;
    await setDefaultPrinter(defaultPrinterRef.value);

    toast.add({
      severity: "success",
      summary: t("printing.default-printer-saved"),
    });

    emit("update:visibleDialog", false);
  } finally {
    isSaving.value = false;
  }
};

const rules = {
  printerName: {
    required,
  },
  location: {
    required,
  },
  description: {
    required,
  },
};

const val = useVuelidate(rules, defaultPrinterRef);
</script>
