<template>
  <FloatLabel variant="on">
    <InputText
      id="email"
      v-model="emailComputed"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('client.email').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
      type="text"
      data-testid="client-email"
      class="inputfield w-full"
      aria-describedby="email-help"
      :class="{ 'p-invalid': val.email.$error }"
    />
    <label for="email">{{ t("client.email") }}</label>
  </FloatLabel>
  <small v-if="val.email.$error" data-testid="client-email-error" class="p-error">{{
    val.email.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required, email as validateEmail } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  email: string;
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const emailComputed = computed<string>({
  get: () => {
    return props.email ?? "";
  },
  set: (value) => {
    emit("update:email", value);
  },
});

const { t } = useI18n();

const rules = {
  email: {
    validateEmail: helpers.withMessage(() => t("validations.email"), validateEmail),
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
