import { type Component } from "vue";
import { type RouteRecordRaw } from "vue-router";

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Routes",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("../components/OAuth2Signout.vue"),
        },
        {
          name: "Logout",
          path: "/logout",
          component: (): Component => import("../components/Logout.vue"),
        },
        {
          path: "/failed",
          name: "Failed",
          component: (): Component => import("../components/Failed.vue"),
        },
      ]
    : [];

export const employeeRoutes: RouteRecordRaw[] = [
  {
    name: "employee-search",
    path: "/system-data/employees",
    component: (): Component => import("@/employee/list/EmployeeSearch.vue"),
    meta: {
      authRequired: true,
      title: "employee.search-employee",
    },
  },
  {
    name: "employee-add",
    path: "/system-data/employee/add",
    component: (): Component => import("@/employee/add/EmployeeAdd.vue"),
    meta: {
      authRequired: true,
      title: "employee.add-employee",
    },
  },
  {
    name: "employee-edit",
    path: "/system-data/employee/edit/:id",
    component: (): Component => import("@/employee/edit/EmployeeEdit.vue"),
    meta: {
      authRequired: true,
      title: "employee.edit-employee",
    },
  },
];

export const companyRoutes: RouteRecordRaw[] = [
  {
    name: "company-info",
    path: "/system-data/company",
    component: (): Component => import("@/company/Company.vue"),
    meta: {
      authRequired: true,
      title: "company.header-info",
    },
  },
];

export const clientRoutes: RouteRecordRaw[] = [
  {
    name: "client-search",
    path: "/system-data/clients",
    component: (): Component => import("@/client/list/ClientSearch.vue"),
    meta: {
      authRequired: true,
      title: "client.search-client",
    },
  },
  {
    name: "client-add",
    path: "/system-data/client/add",
    component: (): Component => import("@/client/add/ClientAdd.vue"),
    meta: {
      authRequired: true,
      title: "client.add-client",
    },
  },
  {
    name: "client-edit",
    path: "/system-data/client/edit/:id",
    component: (): Component => import("@/client/edit/ClientEdit.vue"),
    meta: {
      authRequired: true,
      title: "client.edit-client",
    },
  },
];

export const settingsRoutes = [
  {
    name: "settings",
    path: "/system-data/settings",
    component: (): Component => import("@/settings/Overview.vue"),
    meta: {
      authRequired: true,
      title: "settings.settings-header",
    },
  },
];

export const importRoutes = [
  {
    name: "imports",
    path: "/system-data/imports",
    component: (): Component => import("@/import/Imports.vue"),
    meta: {
      authRequired: true,
      title: "settings.import.header",
    },
  },
];

export const printerRoutes = [
  {
    name: "printing",
    path: "/system-data/printing",
    component: (): Component => import("@/printing/views/Printers.vue"),
    meta: {
      authRequired: true,
      title: "printing.header",
    },
  },
];
