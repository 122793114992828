<template>
  <div v-if="useTeleport" id="company-navbar-shortcuts">
    <Teleport to="#c-navbar-title">
      <h1>{{ t("company.header-info") }}</h1>
    </Teleport>
    <Teleport to="#c-navbar-shortcuts">
      <div class="c-header-icon-container">
        <Button class="c-circular-button" data-testid="btn-close" @click="$emit('onClose')">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.close") }}</span></Button
        >
      </div>
    </Teleport>
  </div>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-close"
        icon="pi pi-arrow-left"
        rounded
        size="small"
        variant="text"
        data-testid="btn-close"
        @click="$emit('onClose')"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800 dark:text-white">{{ t("common.cancel") }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const useTeleport = !(import.meta.env.VITE_APP_STANDALONE === "true" || import.meta.env.MODE === "test");

defineEmits<{
  (e: "onClose"): void;
}>();
</script>
